import { Suspense, lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Loading from "./components/Loading";

const Builder = lazy(() => import("./pages/commons/BUILDER"));
const Builder_Visio_Expert = lazy(() => import("./pages/commons/BUILDER_VISIO_EXPERIMENTE"));
const Builder_Residentiel = lazy(() => import("./pages/commons/BUILDER_RESIDENTIEL"));
const WhoAreYou = lazy(() => import("./pages/commons/WHOAREYOU"));
const AUTO_GEO_REDIRECT = lazy(() => import("./components/AutoGeoLocation"));

const Checkout = lazy(() => import("./components/Checkout"));
const CheckoutCancel = lazy(() => import("./components/Checkout/Cancel"));
const CheckoutSuccess = lazy(() => import("./components/Checkout/Success"));
const CheckoutSuccessResidentiel = lazy(() => import("./components/Checkout/SuccessResidentiel"));

export default function App() {
  return <Suspense fallback={<Loading />}>
      <BrowserRouter>
        <Routes>
          {/* NOT FOUNDED PAGE OR ROOT PAGE ! */}
          <Route exact path="/" element={<WhoAreYou />} />
          <Route path="*" element={<WhoAreYou />} />

          {/* Visio Experimente */}
          <Route path="/visio-expert" element={<Builder_Visio_Expert />} />
          <Route path="/fr/visio-expert" element={<Builder_Visio_Expert />} />
          <Route path="/be/visio-expert" element={<Builder_Visio_Expert />} />
          <Route path="/ma/visio-expert" element={<Builder_Visio_Expert />} />
          <Route path="/ca/visio-expert" element={<Builder_Visio_Expert />} />
          <Route path="/ch/visio-expert" element={<Builder_Visio_Expert />} />
          <Route path="/lu/visio-expert" element={<Builder_Visio_Expert />} />
          <Route path="/mc/visio-expert" element={<Builder_Visio_Expert />} />

          {/* Geolocalisation */}
          <Route path="/use-auto-locate" element={<AUTO_GEO_REDIRECT />} />

          {/* Paiement */}
          <Route path="/checkout">
            <Route index element={<Checkout />} />
            <Route path="success" element={<CheckoutSuccess />} />
            <Route path="residentiel/success" element={<CheckoutSuccessResidentiel />} />
            <Route path="cancel" element={<CheckoutCancel />} />
          </Route>

          {/* Sur place */}
          <Route path="/fr/oserlechangement">
              <Route path="/fr/oserlechangement/auvergne-rhone-alpes" element={<Builder resolve="/fr/oserlechangement/auvergne-rhone-alpes" />} />
              <Route path="/fr/oserlechangement/bourgogne-franche-comte" element={<Builder resolve="/fr/oserlechangement/bourgogne-franche-comte" />} />
              <Route path="/fr/oserlechangement/bretagne" element={<Builder resolve="/fr/oserlechangement/bretagne" />} />
              <Route path="/fr/oserlechangement/centre-val-de-loire" element={<Builder resolve="/fr/oserlechangement/centre-val-de-loire" />} />
              <Route path="/fr/oserlechangement/corse" element={<Builder resolve="/fr/oserlechangement/corse" />} />
              <Route path="/fr/oserlechangement/grand-est" element={<Builder resolve="/fr/oserlechangement/grand-est" />} />
              <Route path="/fr/oserlechangement/hauts-de-france" element={<Builder resolve="/fr/oserlechangement/hauts-de-france" />} />
              <Route path="/fr/oserlechangement/ile-de-france" element={<Builder resolve="/fr/oserlechangement/ile-de-france" />} />
              <Route path="/fr/oserlechangement/normandie" element={<Builder resolve="/fr/oserlechangement/normandie" />} />
              <Route path="/fr/oserlechangement/nouvelle-aquitaine" element={<Builder resolve="/fr/oserlechangement/nouvelle-aquitaine" />} />
              <Route path="/fr/oserlechangement/occitanie" element={<Builder resolve="/fr/oserlechangement/occitanie" />} />
              <Route path="/fr/oserlechangement/pays-de-la-loire" element={<Builder resolve="/fr/oserlechangement/pays-de-la-loire" />} />
              <Route path="/fr/oserlechangement/provence-alpes-cote-d-azur" element={<Builder resolve="/fr/oserlechangement/provence-alpes-cote-d-azur" />} />
              <Route path="/fr/oserlechangement/dom-tom" element={<Builder resolve="/fr/oserlechangement/dom-tom" />} />
          </Route>
          <Route path="/be/oserlechangement/belgique" element={<Builder resolve="/be/oserlechangement/belgique" />} />
          <Route path="/ma/oserlechangement/maroc" element={<Builder resolve="/ma/oserlechangement/maroc" />} />
          <Route path="/ca/oserlechangement/canada" element={<Builder resolve="/ca/oserlechangement/canada" />} />
          <Route path="/ch/oserlechangement/suisse" element={<Builder resolve="/ch/oserlechangement/suisse" />} />
          <Route path="/lu/oserlechangement/luxembourg" element={<Builder resolve="/lu/oserlechangement/luxembourg" />} />
          <Route path="/mc/oserlechangement/monaco" element={<Builder resolve="/mc/oserlechangement/monaco" />} />

          {/* Residentiel */}
          <Route path="/fr/residentiel" element={<Builder_Residentiel resolve="/fr/residentiel" />} />
          <Route path="/be/residentiel" element={<Builder_Residentiel resolve="/be/residentiel" />} />
          <Route path="/ma/residentiel" element={<Builder_Residentiel resolve="/ma/residentiel" />} />
          <Route path="/ca/residentiel" element={<Builder_Residentiel resolve="/ca/residentiel" />} />
          <Route path="/ch/residentiel" element={<Builder_Residentiel resolve="/ch/residentiel" />} />
          <Route path="/lu/residentiel" element={<Builder_Residentiel resolve="/lu/residentiel" />} />

        </Routes>
      </BrowserRouter>
  </Suspense>
}